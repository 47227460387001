import React from "react";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import BibleDropdown from "./components/BibleDropdown";
import SelectedBibleText from "./components/SelectedBibleText";
import BibleDropdown2 from "./components/BibleDropdown2";
import SelectedBibleText2 from "./components/SelectedBibleText2";
import BibleDropdown3 from "./components/BibleDropdown3";
import SelectedBibleText3 from "./components/SelectedBibleText3";
import BibleDropdown4 from "./components/BibleDropdown4";
import SelectedBibleText4 from "./components/SelectedBibleText4";
import Menu from "./Menu";

import GeneratePDFButton from "./GeneratePDFButton";

function Pagina2({
  selectedData,
  setSelectedData,
  selectedData2,
  setSelectedData2,
  selectedData3,
  setSelectedData3,
  selectedData4,
  setSelectedData4,
  info,
  handleOnchange,
}) {
  return (
    <div className="col-11 mt-5">
      <div id="Pagina2ContentId">
        <div>
          <Menu />
        </div>

        <Table className="table table-bordered border border-3 border-dark text-start m-5">
          <tbody>
            <tr>
              <td colSpan={2}>
                <h3>
                  IDEAS CENTRALES FUNDAMENTADAS APLICANDO LOS PRINCIPIOS DE LA
                  HERMENÉUTICA
                </h3>
              </td>
              <td>
                <h4>REFERENCIAS BIBLIOGRÁFICAS / FUENTES DE INFORMACIÓN</h4>
              </td>
            </tr>
            <tr>
              <td className="col-1" rowSpan={5}>
                <h2>ICF1</h2>
              </td>
              <td className="bg-light col-8">
                <input
                  type="text"
                  placeholder="Titulo"
                  name="icf1"
                  className="col-12"
                  value={info.icf1 || ""}
                  onChange={handleOnchange}
                />
                <SelectedBibleText selectedData={selectedData} />
              </td>
              <td className=" col-3 bg-light">
                <h2 className="bg-citas">Citas:</h2>
                <BibleDropdown
                  setSelectedData={setSelectedData}
                  selectedData={selectedData}
                />
              </td>
            </tr>

            <tr>
              <td className="bg-light">
                <SelectedBibleText2 selectedData2={selectedData2} />
              </td>
              <td className="bg-light">
                <h2 className="bg-citas"> Adicionales:</h2>
                <BibleDropdown2
                  setSelectedData2={setSelectedData2}
                  selectedData2={selectedData2}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-light">
                <SelectedBibleText3 selectedData3={selectedData3} />
              </td>
              <td className="bg-light">
                <BibleDropdown3
                  setSelectedData3={setSelectedData3}
                  selectedData3={selectedData3}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-light">
                <SelectedBibleText4 selectedData4={selectedData4} />
              </td>
              <td className="bg-light">
                <BibleDropdown4
                  setSelectedData4={setSelectedData4}
                  selectedData4={selectedData4}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-light">
                <textarea name="" className="col-12" id=""></textarea>
              </td>
              <td className="bg-light">
                <textarea
                  name=""
                  className="col-12"
                  placeholder="Adicionales:"
                  id=""
                ></textarea>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      <div className="d-grid d-flex justify-content-around col-12 my-5">
        <Link to="/">
          <button className="btn btn-primary btn-lg px-5">Atras</button>
        </Link>
        <GeneratePDFButton contentId="Pagina2ContentId" filename="ICF1.pdf" />
        <Link to="/icf2">
          <button className="btn btn-primary btn-lg px-4">Siguiente</button>
        </Link>
      </div>
    </div>
  );
}

export default Pagina2;
