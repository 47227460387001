import React from "react";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

import GeneratePDFButton from "./GeneratePDFButton";

function Pagina3({ info, handleOnchange }) {
  return (
    <div className="col-11 mt-5">
      <div id="Pagina3ContentId">
        <Table className="table table-bordered border border-3 border-dark text-start text">
          <tbody>
            <tr>
              <td className="col-1" rowSpan={15}>
                <h2 className="mt-5">35 min</h2>
              </td>
              <td className="bg-light" colSpan={2}>
                <h4>
                  2.- FACILITA EL PROCESO DE COMPRENSIÓN MEDIANTE LA EXPOSICIÓN
                  DEL DISCURSO AL IMPARTIR LA CONFERENCIA:
                </h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <h4>
                  · Dirigiendo la mirada a toda la audiencia durante el
                  desarrollo de la conferencia, acorde a las condiciones de
                  Interacción,
                </h4>
                <h4>
                  · Empleando expresiones faciales/gestos, ademanes, posturas y
                  congruentes con el contenido que se transmite,{" "}
                </h4>
                <h4>
                  • Manteniendo una postura dinámica dentro del espacio de la
                  conferencia, con el fin de captar/enganchar la atención de la
                  audiencia,
                </h4>
                <h4>
                  • Utilizando variantes de tono, modulación, ritmo y volumen de
                  voz durante la impartlción de la conferencia para enfatizar
                  información prioritaria en el{" "}
                </h4>
                <h4>
                  • REFORZANDO EL TEMA DE LA CONFERENCIA CON TEXTOS DE ENFOQUE
                  TEOLÓGICO BASE/OTROS ADICIONALES, AL CITAR LA FUENTE DE
                  ORIGEN/AUTOR,
                </h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <h4>
                  ·Explicando las ideas centrales de manera detallada y lo
                  fundamental con base en las referencias bibliográficas,
                  información de enfoque teológico.
                </h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <h4>{info.icf1}</h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <textarea
                  type="text"
                  placeholder="Escribe aqui"
                  name="desarrolloicf1"
                  className="col-12"
                  value={info.desarrolloicf1 || ""}
                  onChange={handleOnchange}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <h4>{info.icf2}</h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <textarea
                  type="text"
                  placeholder="Escribe aqui"
                  name="desarrolloicf2"
                  className="col-12"
                  value={info.desarrolloicf2 || ""}
                  onChange={handleOnchange}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <h4>{info.icf3}</h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <textarea
                  type="text"
                  placeholder="Escribe aqui"
                  name="desarrolloicf3"
                  className="col-12"
                  value={info.desarrolloicf3 || ""}
                  onChange={handleOnchange}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <h4>
                  Expresando EXPERIENCIAS VIVENCIALES/PERSONALES (TESTIMONIO
                  PERSONAL), con relación al tema desarrollado con el fin de
                  generar y fortalecer empatía con la audiencia,{" "}
                </h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <textarea
                  type="text"
                  placeholder="Escribe aqui"
                  name="testimonio"
                  className="col-12"
                  value={info.testimonio || ""}
                  onChange={handleOnchange}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <h4>
                  Utilizando ejemplos sobre VIVENCIAS/EXPERIENCIAS DE TERCEROS
                  (TESTIMONIO DE OTROS) relacionándolos con el tema tratado con
                  el fin de generar y fortalecer empatía con la audiencia,
                </h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <textarea
                  type="text"
                  placeholder="Escribe aqui"
                  name="testimonio2"
                  className="col-12"
                  value={info.testimonio2 || ""}
                  onChange={handleOnchange}
                />
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <h4>
                  Mencionando a la audiencia que los errores/fallas/omisiones
                  presentados en situaciones cotidianas, son oportunidades para
                  reflexionar sobre los temas abordados
                </h4>
              </td>
            </tr>
            <tr>
              <td className="bg-light" colSpan={2}>
                <textarea
                  type="text"
                  placeholder="Escribe aqui"
                  name="testimonio2"
                  className="col-12"
                  value={info.testimonio2 || ""}
                  onChange={handleOnchange}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="d-grid d-flex justify-content-around col-12 m-5">
        <Link to="/encuadre">
          <button className="btn btn-primary btn-lg px-5">Atras</button>
        </Link>
        <GeneratePDFButton
          contentId="Pagina3ContentId"
          filename="Pagina3.pdf"
        />
        <Link to="/pagina4">
          <button className="btn btn-primary btn-lg px-4">Siguiente</button>
        </Link>
      </div>
    </div>
  );
}

export default Pagina3;
