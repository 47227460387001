import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "./App.css";

function Menu() {
  return (
    <div className="d-flex container-fluid ">
      <Navbar className="bg-white col-12 mt-5 ">
        <div className="col-12 ">
          <div className="d-flex ">
            <div className="d-flex col-3 margin-logo mt-4 position-absolute bg-white">
              <div className="mt-5">
                <img
                  src="/img/logo_conoce.png"
                  width="80"
                  height="80"
                  className="align-top mx-4"
                  alt="React Bootstrap logo"
                />
              </div>

              <div className="my-5 mx-4">
                <img
                  src="/img/logo_ece.png"
                  width="80"
                  height="80"
                  className="d-inline-block align-top p-1"
                  alt="React Bootstrap logo"
                />
              </div>
              <div className="my-5 mx-4">
                <img
                  src="/img/logo_uni.png"
                  width="60"
                  height="60"
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
              </div>
            </div>

            <div className="text-dark mt-3 d-flex flex-column align-items-start menu-margin">
              <div>
                <h6>DIRECCIÓN GENERAL</h6>
              </div>
              <div>
                <h6>ENTIDAD DE CERTIFICACIÓN Y EVALUACIÓ N ECE500-21</h6>
              </div>
              <div>
                <h6>INSTITUTO UNIVERSITARIO AZTECA DE CHIAPAS</h6>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="pole2 col-3 "></div>
            <div className="pole col-9 "></div>
          </div>

          <p className="text-dark mt-2 font1 ">PLAN DE LA CONFERENCIA EC1476</p>
          <h6 className="text-dark mt-0 text-MARGIN">
            F-PLCONF-EC1476V1-ECE500-21-06-24
          </h6>
        </div>
      </Navbar>
    </div>
  );
}

export default Menu;
